import { Typography } from '@org-crowley/enterprise-react-component-library';
import React, { useMemo } from 'react';
import { IconType } from 'react-icons';
import { MdSpeed } from 'react-icons/md';
import cn from 'classnames';
import { formatContent, formatValue } from '../../utils/formatLargeNumbers';

interface Props {
  ratioDataIcon?: IconType;
  ratioDataValue?: number | undefined;
  ratioDataUnit?: string | undefined;
  ratioDataLabel?: string;
  className?: string;
}

export function RatioDataCard({
  ratioDataIcon,
  ratioDataValue = 0,
  ratioDataUnit,
  ratioDataLabel,
  className
}: Props) {
  const Icon = ratioDataIcon || MdSpeed;

  const valueInformation = useMemo(() => {
    const formattedValue = formatValue(ratioDataValue);
    return formatContent(formattedValue, ratioDataValue);
  }, [ratioDataValue]);

  return (
    <div
      data-testid={`Radio Data Card ${ratioDataLabel}`}
      className={cn('bg-silver-10 flex items-center', className)}
    >
      <div className="py-3 px-6 w-full">
        <div className="w-100 text-right">
          <Typography variant="h100" className="text-silver-90">
            {ratioDataUnit}
          </Typography>
        </div>
        <div className="flex flex-row items-center">
          <Icon size={14} className="text-silver-40" />
          <Typography variant="h700" className="pl-3 min-w-[50%] text-center">
            {valueInformation}
          </Typography>
        </div>
        <Typography
          as="div"
          variant="h100"
          className="font-normal text-silver-90 overflow-hidden w-full text-ellipsis"
        >
          {ratioDataLabel}
        </Typography>
      </div>
    </div>
  );
}
