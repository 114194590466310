import 'mapbox-gl/dist/mapbox-gl.css';
import { ActionBar } from '../components/ActionBar/ActionBar';
import { MapSidebar } from '../components/MapSidebar/MapSidebar';
import {
  DEFAULT_LAYER,
  DEFAULT_MAP_OPTIONS,
  Map,
  MapLayer,
  MapOptions
} from '../components/Map/Map';
import { useReducer, useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { withAuth } from '../components/withAuth';
import { routeReducer } from '../reducers/routeReducer';
import { useLocalStorageBackedState } from '../utils/useLocalStorageBackedState';
import {
  MAP_LAYER_PROPS_STORAGE_KEY,
  MAP_OPTION_PROPS_STORAGE_KEY
} from '../utils/localStorageKeys';
import { RESPONSIVE_BREAKPOINTS } from '@org-crowley/enterprise-react-component-library';

export function Index() {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(true);
  const width = useWindowWidth();

  const [mapLayerProperties, setMapLayerProperties] =
    useLocalStorageBackedState<MapLayer>({
      key: MAP_LAYER_PROPS_STORAGE_KEY,
      initialValue: DEFAULT_LAYER,
      persist: true
    });

  const [mapOptionProperties, setMapOptionProperties] =
    useLocalStorageBackedState<MapOptions>({
      key: MAP_OPTION_PROPS_STORAGE_KEY,
      initialValue: DEFAULT_MAP_OPTIONS,
      persist: true
    });

  const [routeState, routeDispatch] = useReducer(routeReducer, {
    shouldFetchRoutes: false,
    currentRouteData: null,
    routeErrorText: null,
    selectedRoute: null,
    showRouteHeatMap: false,
    routeStartTimestamp: null
  });

  return (
    <>
      <ActionBar />
      <main className="flex overflow-hidden">
        {width > RESPONSIVE_BREAKPOINTS.medium && (
          <MapSidebar
            sidebarIsOpen={sidebarIsOpen}
            onSidebarIconClick={() => setSidebarIsOpen((prev) => !prev)}
            routeState={routeState}
            routeDispatch={routeDispatch}
            mapLayer={mapLayerProperties.name}
          />
        )}
        <Map
          routeState={routeState}
          routeDispatch={routeDispatch}
          mapLayerProperties={mapLayerProperties}
          setMapLayerProperties={setMapLayerProperties}
          mapOptionProperties={mapOptionProperties}
          setMapOptionProperties={setMapOptionProperties}
        />
      </main>
    </>
  );
}

export default withAuth(Index);
