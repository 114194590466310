import { IconType } from 'react-icons';
import { GaugeDataCard } from './GaugeDataCard';
import { RatioDataCard } from './RatioDataCard';
import cn from 'classnames';

export enum TachometerStatus {
  Inactive,
  Normal,
  Warning,
  Critical
}

export enum TachometerVariant {
  HorizontalLeft,
  HorizontalRight,
  SingleData
}

interface Props {
  variant?: TachometerVariant;
  gaugeDataValue: number | undefined;
  gaugeDataUnit: string | undefined;
  gaugeDataStatus: TachometerStatus;
  gaugeDataLabel: string;
  ratioDataIcon?: IconType;
  ratioDataValue?: number | undefined;
  ratioDataUnit?: string | undefined;
  ratioDataLabel?: string;
  className?: string;
}

export function TachometerCard({
  variant = TachometerVariant.SingleData,
  className,
  ...props
}: Props) {
  let content;

  const HorizontalRightCard = (
    <div
      data-testid={`Horizontal Right Card ${props.gaugeDataLabel}`}
      className={cn(
        'border border-silver-30 rounded-md flex flex-row drop-shadow-md w-full h-[160px]',
        className
      )}
    >
      <RatioDataCard
        {...props}
        className="rounded-l-md border-r-2 border-silver-30 w-[50%]"
      />
      <GaugeDataCard {...props} className="rounded-r-md w-[50%]" />
    </div>
  );

  const HorizontalLeftCard = (
    <div
      data-testid={`Horizontal Left Card ${props.gaugeDataLabel}`}
      className={cn(
        'border border-silver-30 rounded-md flex flex-row drop-shadow-md w-full h-[160px]',
        className
      )}
    >
      <GaugeDataCard
        {...props}
        className="rounded-l-md border-r-2 border-silver-30 w-[50%]"
      />
      <RatioDataCard {...props} className="rounded-r-md w-[50%]" />
    </div>
  );

  const SingleDataCard = (
    <div
      data-testid={`Single Data Card ${props.gaugeDataLabel}`}
      className={cn(
        'border border-silver-30 rounded-md flex drop-shadow-md w-full h-[160px]',
        className
      )}
    >
      <GaugeDataCard {...props} className="rounded-md w-full" />
    </div>
  );

  switch (variant) {
    case TachometerVariant.HorizontalRight:
      content = HorizontalRightCard;
      break;
    case TachometerVariant.HorizontalLeft:
      content = HorizontalLeftCard;
      break;
    default:
      content = SingleDataCard;
      break;
  }

  return content;
}
