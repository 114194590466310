import { BlankButton } from '@org-crowley/enterprise-react-component-library';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { Dispatch } from 'react';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import { RouteAction, RouteState } from '../../reducers/routeReducer';
import { VesselOverview } from '../VesselOverview/VesselOverview';
import { VesselSummary } from '../VesselSummary/VesselSummary';

interface Props {
  onSidebarIconClick: () => void;
  sidebarIsOpen: boolean;
  routeState: RouteState;
  routeDispatch: Dispatch<RouteAction>;
  mapLayer: string;
}

export function MapSidebar({
  onSidebarIconClick,
  sidebarIsOpen,
  routeState,
  routeDispatch,
  mapLayer
}: Props) {
  const { query } = useRouter();

  const isSummary = !!query.summary;

  return (
    <div
      data-testid="map-sidebar-container"
      className={cn('relative', 'transition-all', 'duration-300', 'flex-[0]', {
        'min-w-[480px]': sidebarIsOpen,
        'min-w-0 p-0': !sidebarIsOpen
      })}
    >
      <div className="h-full overflow-y-auto">
        {isSummary ? (
          <VesselSummary
            routeState={routeState}
            routeDispatch={routeDispatch}
            mapLayer={mapLayer}
          />
        ) : (
          <VesselOverview />
        )}
      </div>

      <BlankButton
        onClick={onSidebarIconClick}
        data-testid="map-sidebar-toggle-button"
        className={cn(
          'w-7',
          'pr-1',
          'pl-1',
          'absolute',
          'top-2/4',
          'rounded-l-none',
          'transition-all',
          'duration-300',
          'z-10',
          'bg-white',
          'h-14',
          {
            'left-[480px]': sidebarIsOpen,
            'left-0': !sidebarIsOpen
          }
        )}
      >
        <div
          className={cn('transition-transform duration-300', {
            'rotate-180': !sidebarIsOpen
          })}
        >
          <MdOutlineArrowBackIos />
        </div>
      </BlankButton>
    </div>
  );
}
