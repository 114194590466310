import {
  Modal,
  ModalContent,
  ModalHeader,
  Typography
} from '@org-crowley/enterprise-react-component-library';
import { StatusIcon } from '../StatusIcon/StatusIcon';
import { VesselStatus } from '../../models/Vessel';

interface IconLegendModalProps {
  isModalOpen: boolean;
  onModalClose: () => void;
}

export function IconLegendModal({
  isModalOpen,
  onModalClose
}: IconLegendModalProps) {
  return (
    <Modal
      size="medium"
      footerButtonText="Done"
      isOpen={isModalOpen}
      onCloseButtonClick={onModalClose}
    >
      <ModalHeader onCloseButtonClick={onModalClose}>
        <Typography variant="h300">Vessel Overview</Typography>
      </ModalHeader>
      <ModalContent>
        <div
          className="mb-6 flex items-start"
          data-testid="icon-legend-modal-moving"
        >
          <StatusIcon vesselStatus={VesselStatus.Moving} className="mr-6" />
          <Typography variant="body-small" as="span" className="text-silver-90">
            Moving:
            <ul className="list-disc pl-6">
              <li>Under way using engine</li>
              <li>Under way sailing</li>
              <li>Power-driven towing astern (regional use)</li>
              <li>
                Power-driven vessel pushing ahead or towing alongside (regional
                use)
              </li>
            </ul>
          </Typography>
        </div>
        <div className="mb-6 flex items-start">
          <StatusIcon vesselStatus={VesselStatus.Stopped} className="mr-6" />
          <Typography variant="body-small" as="span" className="text-silver-90">
            Stopped:
            <ul className="list-disc pl-6">
              <li>At anchor</li>
              <li>Restricted maneuverability</li>
              <li>Constrained by her draught</li>
              <li>Moored</li>
              <li>Aground</li>
            </ul>
          </Typography>
        </div>
        <div className="flex items-start">
          <StatusIcon
            vesselStatus={VesselStatus.OutOfService}
            className="mr-6"
          />
          <Typography variant="body-small" as="span" className="text-silver-90">
            Out of Service:
            <ul className="list-disc pl-6">
              <li>N/A</li>
            </ul>
          </Typography>
        </div>
      </ModalContent>
    </Modal>
  );
}
