import { Tooltip } from '@org-crowley/enterprise-react-component-library';

const MAX_VALUE_LENGTH = 5;

const getlength = (number: number) => {
  return number.toString().length;
};

export const formatValue = (
  value: number | undefined,
  maxLength: number = MAX_VALUE_LENGTH
) => {
  if (!value) return 0;
  return getlength(value) > maxLength
    ? new Intl.NumberFormat('en-US', {
        notation: 'compact',
        compactDisplay: 'short'
      }).format(value)
    : value;
};

export const formatContent = (
  formattedValue: number | string,
  originalValue: number | undefined
): React.ReactNode => {
  let content = <>{formattedValue.toLocaleString()}</>;
  if (typeof formattedValue === 'string' && originalValue) {
    content = (
      <Tooltip tooltipText={originalValue.toLocaleString()}>
        {formattedValue}
      </Tooltip>
    );
  }
  return content;
};
