import {
  merge,
  cloneDeep,
  isEqual,
  isNil,
  isNaN,
  keyBy,
  orderBy,
  uniqBy,
  values
} from 'lodash';
import { Layout } from 'react-grid-layout';
import { ResponsiveGridLayout } from '../components/GaugeDashboard/GaugeDashboard';
import { BarefleetItem, UnitValue } from '../models/Barefleet';
import { TachometerStatus } from '../components/TachometerCard/TachometerCard';

export const isSameLayout = (
  newLayout: ResponsiveGridLayout | Layout[],
  prevLayout: ResponsiveGridLayout | Layout[]
) => {
  if ((!prevLayout && newLayout) || (!newLayout && prevLayout)) return false;
  const both = merge(cloneDeep(newLayout), cloneDeep(prevLayout));
  return isEqual(both, newLayout);
};

export const getStatus = (unitValue: Partial<UnitValue>): TachometerStatus => {
  const alert = unitValue?.alert;
  let status: TachometerStatus;
  if (alert === true) {
    status = TachometerStatus.Critical;
  } else if (isNil(alert)) {
    status = TachometerStatus.Inactive;
  } else {
    status = TachometerStatus.Normal;
  }
  return status;
};

export const formatCurrentDashboardTime = (
  time: string | string[] | undefined,
  showRoute: string | string[] | undefined
): number | undefined => {
  //query value is in seconds, we should return it on milliseconds
  let formattedTime: number | undefined;
  if (isNil(time) || showRoute !== 'true') {
    formattedTime = undefined;
  } else if (isNaN(Number(time))) {
    /** if there is no valid number, return todays date, then at 
    validateCurrentDashboardTime() we will validate if the date is 
    valid depending on the vessel's voyage**/
    formattedTime = +new Date();
  } else if (time === '0') {
    formattedTime = 1000;
  } else {
    formattedTime = Number(time);
  }
  return formattedTime;
};

export const validateCurrentDashboardTime = (
  currentDashboardTime: number | undefined,
  minAllowedTime: number,
  maxAllowedTime: number
) => {
  let initialRouteTime;
  if (!currentDashboardTime || currentDashboardTime > maxAllowedTime)
    initialRouteTime = maxAllowedTime;
  else if (currentDashboardTime < minAllowedTime)
    initialRouteTime = minAllowedTime;
  else initialRouteTime = currentDashboardTime;

  return initialRouteTime;
};

export const createDate = (
  day: Date | undefined,
  hours: number,
  minutes: number
) => {
  const date = new Date(day || '');
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
};

export const formatAsTime = (input: number) => {
  let formattedInput = input.toString();
  if (input.toString().length === 1) {
    formattedInput = `0${input}`;
  } else if (input.toString().length > 1) {
    formattedInput = input.toString().slice(-2);
  }
  return formattedInput;
};

export const mergeArraysByKey = (
  arrayA: BarefleetItem[] | undefined,
  arrayB: BarefleetItem[] | undefined,
  key: string,
  order?: 'asc' | 'desc'
) => {
  const merged = merge(keyBy(arrayA, key), keyBy(arrayB, key));
  const unique = uniqBy(values(merged), key);
  const ordered = orderBy(unique, [key], [order || 'asc']);
  return ordered;
};
