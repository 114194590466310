import { Typography } from '@org-crowley/enterprise-react-component-library';
import cn from 'classnames';
import { useMemo } from 'react';
import { TachometerStatus } from './TachometerCard';
import { formatContent, formatValue } from '../../utils/formatLargeNumbers';

interface Props {
  gaugeDataValue: number | undefined;
  gaugeDataUnit: string | undefined;
  gaugeDataStatus: TachometerStatus;
  gaugeDataLabel: string;
  className?: string;
}

export function GaugeDataCard({
  gaugeDataValue,
  gaugeDataUnit,
  gaugeDataStatus,
  gaugeDataLabel,
  className
}: Props) {
  const valueInformation = useMemo(() => {
    const formattedValue = formatValue(gaugeDataValue);
    return formatContent(formattedValue, gaugeDataValue);
  }, [gaugeDataValue]);

  return (
    <div className={cn('bg-white py-2 px-5 min-w-[148px]', className)}>
      <div
        data-testid={`Gauge Data Card ${gaugeDataLabel}`}
        className={cn(
          'rounded-full border-4 h-[110px] w-[110px] flex justify-center items-center mx-auto flex-col',
          {
            'border-green-50 bg-silver-10':
              gaugeDataStatus === TachometerStatus.Normal
          },
          {
            'border-silver-50 bg-silver-10':
              gaugeDataStatus === TachometerStatus.Inactive
          },
          {
            'border-yellow-50 bg-yellow-10':
              gaugeDataStatus === TachometerStatus.Warning
          },
          {
            'border-red-50 bg-red-5':
              gaugeDataStatus === TachometerStatus.Critical
          }
        )}
      >
        <Typography variant="h600">{valueInformation}</Typography>
        <Typography variant="h100" className="text-silver-90">
          {gaugeDataUnit}
        </Typography>
      </div>
      <Typography
        as="div"
        variant="h100"
        className="font-normal text-center pt-3 w-full overflow-hidden text-ellipsis text-silver-90 h-10"
      >
        {gaugeDataLabel}
      </Typography>
    </div>
  );
}
