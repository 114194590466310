import {
  Button,
  IconButton,
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  Tooltip,
  Typography,
  SpecSheet,
  ActionDropdown,
  Divider,
  OPERATING_SYSTEM,
  getOperatingSystem
} from '@org-crowley/enterprise-react-component-library';
import {
  MdInfoOutline,
  MdOutlineMoreHoriz,
  MdShare,
  MdOpenInNew,
  MdGpsFixed,
  MdClose
} from 'react-icons/md';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { VesselContext } from '../../contexts/VesselContext';
import { formatLatLong } from '../../utils/formatLatLong';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { DateDisplay } from '../DateDisplay/DateDisplay';
import { StackedText } from '../StackedText/StackedText';
import { DETAILS_PAGE_URL, HOME_PAGE_URL } from '../../utils/routes';
import { StatusBadge } from '../StatusBadge/StatusBadge';
import { DATA_PENDING_TEXT } from '../../utils/textCopy';
import { formatDirection } from '../../utils/formatVesselInfo';
import {
  RouteAction,
  RouteActionType,
  RouteState
} from '../../reducers/routeReducer';
import { Toggle } from '../Toggle/Toggle';
import { Layer } from '../../models/Route';
import {
  cleanupPreviousRoutes,
  updateCurrentRoute
} from '../../utils/routeMapper';
import { getFullDate } from '../VoyageOverview/VoyageOverview';
import { MAP_ID } from '../Map/Map';
import { useMap } from 'react-map-gl';
import { RangeSlider } from '../RangeSlider/RangeSlider';
import mapboxgl from 'mapbox-gl';
import styles from '../Map/Map.module.css';
import cn from 'classnames';
import { useOktaAuth } from '../../contexts/OktaContext';
import { useVoyageSummaries } from '../../utils/useVoyageSummaries';
import { fetchRoutes } from '../../utils/fetchRoutes';
import {
  formatCurrentDashboardTime,
  validateCurrentDashboardTime
} from '../../utils/dashboardUtils';
import { debounce, isEmpty } from 'lodash';
import LastPositionUpdate from '../LastPositionUpdate/LastPositionUpdate';

export interface VesselSummaryProps {
  routeState: RouteState;
  routeDispatch: Dispatch<RouteAction>;
  mapLayer: string;
}

export function VesselSummary({
  routeState,
  routeDispatch,
  mapLayer
}: VesselSummaryProps) {
  const { query, push } = useRouter();
  const { authState } = useOktaAuth();
  const vesselAssetNumber = query.summary;
  const [currentDashboardTime, setCurrentDashboardTime] = useState(
    formatCurrentDashboardTime(query.time, query.showRoute)
  );
  const map = useMap()[MAP_ID];
  const { vessels } = useContext(VesselContext);
  const [timeLimits, setTimeLimits] = useState<{
    min: number;
    max: number;
  }>();
  const accessToken = authState?.accessToken?.accessToken;
  const [shouldFetchVoyageSummaries, setShouldFetchVoyageSummaries] =
    useState<boolean>(false);
  const { showRouteHeatMap, selectedRoute, currentTime } = routeState;
  const [shouldUpdateMap, setShouldUpdateMap] = useState(
    !!currentDashboardTime ?? false
  );

  useEffect(() => {
    if (
      !!currentDashboardTime &&
      !shouldFetchVoyageSummaries &&
      shouldUpdateMap
    ) {
      setShouldFetchVoyageSummaries(true);
      setShouldUpdateMap(false);
      routeDispatch({
        type: RouteActionType.UPDATE_ROUTE_TIMELINE,
        payload: { currentTime: currentDashboardTime }
      });
    }
  }, [
    currentDashboardTime,
    routeDispatch,
    shouldFetchVoyageSummaries,
    shouldUpdateMap
  ]);

  useEffect(() => {
    if (selectedRoute) {
      const minAllowedTime = Number(
        selectedRoute.routePoints[0].MessageTimestamp
      );
      const maxAllowedTime = Number(
        selectedRoute.routePoints.at(-1)?.MessageTimestamp
      );

      setTimeLimits((prevState) => ({
        ...prevState,
        min: minAllowedTime,
        max: maxAllowedTime
      }));

      const initialRouteTime = validateCurrentDashboardTime(
        currentDashboardTime,
        minAllowedTime,
        maxAllowedTime
      );

      if (!!currentDashboardTime && currentDashboardTime !== initialRouteTime) {
        setCurrentDashboardTime(initialRouteTime);
      }

      routeDispatch({
        type: RouteActionType.UPDATE_ROUTE_TIMELINE,
        payload: { currentTime: initialRouteTime }
      });
    }
  }, [
    selectedRoute,
    routeDispatch,
    mapLayer,
    showRouteHeatMap,
    currentDashboardTime
  ]);

  const vesselInfo = vessels.find(
    (vessel) => vessel.AssetNumber === Number(vesselAssetNumber)
  );

  const onBackClick = () => push(HOME_PAGE_URL);

  const onToggleCurrentRoute = () => {
    if (selectedRoute) {
      cleanupPreviousRoutes(map);
      routeDispatch({
        type: RouteActionType.UPDATE_ALL,
        payload: {
          shouldFetchRoutes: false,
          currentRouteData: null,
          routeErrorText: null,
          selectedRoute: null,
          currentTime: null,
          showRouteHeatMap: false,
          routeStartTimestamp: null
        }
      });
    } else {
      setShouldFetchVoyageSummaries(true);
    }
  };

  const { data: voyageData, error: voyageError } = useVoyageSummaries({
    shouldFetch: shouldFetchVoyageSummaries,
    assetNumber: vesselInfo?.AssetNumber,
    accessToken: accessToken
  });

  useEffect(() => {
    fetchRoutes({
      voyageData,
      voyageError,
      routeDispatch,
      setShouldFetchVoyageSummaries
    });
  }, [voyageData, routeDispatch, voyageError]);

  const onToggleRouteHeatMap = () => {
    routeDispatch({
      type: RouteActionType.TOGGLE_ROUTE_HEAT_MAP,
      payload: {}
    });
  };

  const popup = useRef<mapboxgl.Popup>(
    new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    })
  );

  useEffect(() => {
    popup.current.addClassName(styles.popup);

    if (map && map.getMap) {
      map?.getMap().on('closeAllPopups', () => {
        popup.current.remove();
      });
    }
  }, [map]);

  useEffect(() => {
    popup.current.remove();
  }, [mapLayer]);

  const onUpdateRoute = useCallback(
    (timestampMillisecondsUtc: number) => {
      if (selectedRoute) {
        routeDispatch({
          type: RouteActionType.UPDATE_ROUTE_TIMELINE,
          payload: { currentTime: timestampMillisecondsUtc }
        });
        updateCurrentRoute(
          map,
          selectedRoute,
          timestampMillisecondsUtc,
          popup.current
        );
      }
    },
    [map, routeDispatch, selectedRoute]
  );
  const debounceOnUpdateRoute = useMemo(
    () => debounce(onUpdateRoute, 10),
    [onUpdateRoute]
  );

  const hasMap = !isEmpty(map) && map.getMap();
  const getLayer = (id: string) => hasMap && map.getMap().getLayer(id);
  const historicalRouteLayer = getLayer(Layer.HistoricalRoute);
  const historicalPointsLayer = getLayer(Layer.HistoricalPoints);
  const heatMapLayer = getLayer(Layer.RouteHeatMap);

  // hasLayer ensures that buildRoute() has already run, and the map has the inital route drawn
  const hasLayer = useMemo(() => {
    return !!historicalRouteLayer || !!historicalPointsLayer || !!heatMapLayer;
  }, [historicalRouteLayer, historicalPointsLayer, heatMapLayer]);

  useEffect(() => {
    if (selectedRoute && currentDashboardTime && hasLayer) {
      debounceOnUpdateRoute(currentDashboardTime);
    }
  }, [currentDashboardTime, selectedRoute, hasLayer, debounceOnUpdateRoute]);

  return (
    <Sidebar>
      <SidebarHeader className="z-30">
        <div className="flex justify-between items-center w-full">
          <Button
            variant="tertiary"
            className="!px-3"
            onClick={onBackClick}
            data-testid="vessel-summary-back-button"
            aria-label="close"
          >
            <MdClose size={20} />
          </Button>
          <Typography variant="h300" className="text-silver-90">
            Vessel Summary
          </Typography>
          <ActionDropdown
            items={[
              {
                icon: MdShare,
                label: 'Copy URL',
                onClick: () =>
                  navigator.clipboard.writeText(window.location.href)
              },
              {
                icon: MdOpenInNew,
                label: 'Open in New Window',
                onClick: () =>
                  window.open(
                    `${window.location.origin}/details/${vesselInfo?.AssetNumber}`,
                    '_blank'
                  )
              }
            ]}
          >
            <IconButton Icon={MdOutlineMoreHoriz} disabled={!vesselInfo} />
          </ActionDropdown>
        </div>
      </SidebarHeader>
      <SidebarContent className="px-8 bg-silver-5 flex-col">
        {vesselInfo ? (
          <>
            <div className="flex flex-row justify-between px-4">
              <Typography as="h3" variant="h500">
                {vesselInfo.VesselName}
              </Typography>
              <StatusBadge vessel={vesselInfo} />
            </div>
            <Typography
              variant="body-small"
              className="text-silver-90 px-4 flex items-center"
            >
              <MdGpsFixed
                size={16}
                className={cn('mr-1', 'text-silver-40', {
                  '-mt-1': getOperatingSystem() !== OPERATING_SYSTEM.apple
                })}
              />
              {formatLatLong(vesselInfo.Latitude, vesselInfo.Longitude)}
            </Typography>
            <LastPositionUpdate
              aisLastUpdated={vesselInfo.AISLastUpdated}
              className="px-4 pb-1"
            />
            <SpecSheet
              className="text-silver-90 mt-9"
              statBlocks={[
                {
                  heading: `Voyage ${
                    vesselInfo.VoyageNumber
                      ? `- ${vesselInfo?.VoyageNumber}`
                      : ''
                  }`,
                  stats: [
                    {
                      key: 'Previous Port',
                      value: (
                        <StackedText
                          title={vesselInfo.PreviousPortName}
                          subTitle={vesselInfo.PreviousPortCountry}
                        />
                      )
                    },
                    {
                      key: 'Next Port',
                      value: (
                        <StackedText
                          title={vesselInfo.NextPort}
                          subTitle={DATA_PENDING_TEXT}
                        />
                      )
                    },
                    {
                      key: (
                        <Typography
                          variant="h200"
                          className="flex items-center"
                        >
                          <span className="mr-2">ATD</span>
                          <Tooltip
                            tooltipText="Actual Time Departure"
                            placement="top"
                          >
                            <MdInfoOutline
                              size={16}
                              className="text-silver-40 -mt-1"
                            />
                          </Tooltip>
                        </Typography>
                      ),
                      value: <DateDisplay date={vesselInfo.PreviousPortADT} />
                    },
                    {
                      key: (
                        <Typography
                          variant="h200"
                          className="flex items-center"
                        >
                          <span className="mr-2">ETA</span>
                          <Tooltip
                            tooltipText="Estimated Time Arrival"
                            placement="top"
                          >
                            <MdInfoOutline
                              size={16}
                              className="text-silver-40 -mt-1"
                            />
                          </Tooltip>
                        </Typography>
                      ),
                      value: <DateDisplay date={vesselInfo.ETA} />
                    }
                  ]
                },
                {
                  heading: 'Particulars',
                  stats: [
                    {
                      key: 'Speed Over Ground',
                      value: `${vesselInfo.SpeedOverGround} Knots`
                    },
                    {
                      key: 'Heading',
                      value:
                        vesselInfo.Heading &&
                        formatDirection(vesselInfo.Heading)
                    },
                    {
                      key: 'Vessel Type',
                      value: vesselInfo.VesselType
                    },
                    {
                      key: 'Cargo',
                      value: DATA_PENDING_TEXT
                    }
                  ]
                }
              ]}
              statBlockVariant="stacked"
            />
            <Divider verticalMargin="small" />
            <Typography
              variant="h300"
              as="h2"
              className="mb-4 col-span-2 px-4 text-silver-100"
            >
              Route
            </Typography>
            <div className="px-4">
              <div className="flex flex-col justify-center text-body-small whitespace-nowrap pb-2">
                <Toggle
                  id="toggle-show-current-route"
                  checked={!!selectedRoute}
                  onChange={onToggleCurrentRoute}
                  title="Show Current Route"
                  toggleClassName="after:top-[5px]"
                >
                  <Typography variant="h200" className="text-silver-100">
                    Show Current Route
                  </Typography>
                </Toggle>
              </div>
              {selectedRoute && (
                <div className="flex flex-col justify-center text-body-small">
                  <Toggle
                    id="toggle-route-heat-map"
                    checked={showRouteHeatMap ?? false}
                    onChange={onToggleRouteHeatMap}
                    title="Speed Over Ground Heat Map"
                    toggleClassName="after:top-[5px]"
                  >
                    <Typography variant="h200" className="text-silver-100">
                      SOG Heat Map
                    </Typography>
                  </Toggle>
                </div>
              )}
            </div>

            {currentTime && timeLimits && (
              <span className="text-silver-100">
                <div className="px-4 mt-6">
                  <Typography variant="h200" className="text-center mb-4">
                    See route history
                  </Typography>
                  <RangeSlider
                    id="voyage-range-slider"
                    limits={timeLimits}
                    labels={{
                      min: getFullDate(timeLimits.min),
                      max: getFullDate(timeLimits.max)
                    }}
                    value={currentTime}
                    onChange={(e) =>
                      debounceOnUpdateRoute(Number(e.target.value))
                    }
                  />
                </div>
                <div className="mt-2 mb-1 px-4">
                  <Typography variant="body-small" className="block">
                    Currently showing route history until:
                  </Typography>
                  <Typography variant="body-small">
                    {getFullDate(currentTime)}
                  </Typography>
                </div>
              </span>
            )}
          </>
        ) : (
          <div className="h-full flex flex-col items-center justify-center">
            <ErrorMessage />
          </div>
        )}
      </SidebarContent>
      <SidebarFooter>
        {vesselInfo ? (
          <Link
            href={{
              pathname: `${DETAILS_PAGE_URL}/${vesselInfo.AssetNumber}`,
              query: {
                from: `${HOME_PAGE_URL}?summary=${vesselInfo.AssetNumber}`
              }
            }}
            passHref
          >
            <Button as="a" className="w-full">
              <span
                className={cn({
                  'mt-[3px]': getOperatingSystem() !== OPERATING_SYSTEM.apple
                })}
              >
                More Vessel Details
              </span>
            </Button>
          </Link>
        ) : (
          <Button className="w-full" disabled>
            More Vessel Details
          </Button>
        )}
      </SidebarFooter>
    </Sidebar>
  );
}
